 <template >
  <b-modal
    v-model="showEditNoteModal"
    hide-footer
    :title="'Sửa ghi chú cho phiếu tiếp nhận #' + orderNoteModel.id"
  >
    <b-row class="mb-5">
      <b-col>
        <label class="labelInput">Ghi chú</label>
        <b-form-textarea
          v-model="orderNoteModel.note"
          placeholder="Nhập ghi chú..."
          rows="5"
          max-rows="6"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="$emit('update')"
        >Lưu</b-button>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
        >Hủy</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { UpdateNoteModel } from '@/view/pages/valuation-request/models';
export default {
  name: 'CustomerSupportFormUpdateNoteModal',
  props: {
    showEditNoteModal: {
      type: Boolean,
      default: () => false,
    },
    orderNoteModel: {
      type: Object,
      default: () => {
        return new UpdateNoteModel();
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>
